import { Device, getDevice } from "@/_lib/utils/device";

type DeviceUrlMap = Record<number, string>;

export const constructSecureMessagingChatLink = (
  secureMessagingProvider: string,
  secureMessagingDomain: string,
  secureMessagingAccount: string
): string => {
  const device = getDevice();
  const telmediqDeviceUrlMap: DeviceUrlMap = {
    [Device.Desktop]: `https://${secureMessagingDomain}/resolve-recipients/?email=${secureMessagingAccount}`,
    [Device.Android]: `tmiq://threads/compose?userIdentifier=${secureMessagingAccount}`,
    [Device.IOS]: `sp://threads/compose?email=${secureMessagingAccount}`,
  };

  switch (secureMessagingProvider) {
    case "voalte":
      return device === Device.Desktop
        ? `https://${secureMessagingDomain}/messenger/#/x-callback-url/chat?username=${secureMessagingAccount}`
        : `voalte://x-callback-url/chat?username=${secureMessagingDomain}`;
    case "cortext":
      return `cortext://conversation?email_address=${secureMessagingDomain}`;
    case "vocera":
      return device === Device.Desktop
        ? `http://${secureMessagingDomain}/Invoke.aspx?commMode=Chat&searchScope=email&recipientKey=${secureMessagingDomain}`
        : `voceracs://invoke?commMode=Chat&searchScope=email&recipientKey=${secureMessagingAccount}`;
    case "perfectserve":
      return device === Device.Desktop
        ? `https://${secureMessagingDomain}/message/${secureMessagingAccount}`
        : `pservemobileprod://message/`;
    case "telmediq":
      return telmediqDeviceUrlMap[device];
    case "epic":
      return device === Device.IOS
        ? `epichaiku://launch/securechat/command/newconversation?participantkey=${secureMessagingAccount}`
        : "";
    case "vina":
      return `https://${secureMessagingDomain}/ei/w/SendWebMessage/per_select_phone_search_results_operator3?Devices.usr.login=${secureMessagingAccount}`;
    default:
      return "";
  }
};

export const constructVoalteProfileLink = (secureMessagingProvider: string, username: string): string => {
  return `${secureMessagingProvider}://x-callback-url/profile?username=${username}`;
};

export const constructVoalteCallLink = (secureMessagingProvider: string, phoneNumber: string): string => {
  return `${secureMessagingProvider}://x-callback-url/voice?number=${phoneNumber}`;
};

export const constructProviderName = (secureMessagingProvider: string): string => {
  switch (secureMessagingProvider) {
    case "vina":
      return "Vina Profile";
    default:
      return secureMessagingProvider;
  }
};

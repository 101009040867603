import React from "react";

import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { AssignmentTypesById } from "@/viewer/types/domain/assignmentType";
import { PersonnelTypesById } from "@/viewer/types/domain/personnelType";
import { AssignmentsById } from "@/viewer/types/domain/assignment";
import { PersonnelById } from "@/viewer/types/domain/personnel";
import { TemplatesById } from "@/viewer/types/domain/template";
import { DepartmentsById } from "@/viewer/types/domain/department";
import { ColumnDefinition } from "@/viewer/ui/modules/grid/list/types";
import { User } from "@/viewer/types/domain/user";

import { getSlotDisplayObject, isSlot } from "@/viewer/utils/domain/slotquests";
import { isPhoneNumber } from "@/_lib/utils/phone";
import {
  formatFullHumanizedDate,
  formatLocalTime,
  formatLocalTimeWithMonthAndDay,
  formatUSDate,
} from "@/viewer/utils/dateFormatters";
import {
  constructProviderName,
  constructSecureMessagingChatLink,
  constructVoalteProfileLink,
} from "@/_lib/utils/secureMessaging";
import { isSameDay } from "date-fns";
import { getDisplayElementAndCls } from "@/viewer/ui/modules/common/components/SlotDisplay";
import { getPrecisionDifferenceInHours, timeInMilliseconds } from "@/_lib/utils/dateUtils";

const phoneNumberFactory = (phoneNumber: string): JSX.Element | string => {
  // check value for a phone number match -- if so attach tel: decorators
  if (isPhoneNumber(phoneNumber)) {
    return (
      <a className="no-details" onClick={(e) => e.stopPropagation()} href={"tel:" + phoneNumber}>
        {phoneNumber}
      </a>
    );
  }

  return phoneNumber;
};

const getColumnDefinition = (
  columnName: string,
  settings: SettingsContext,
  ui: UIContext,
  user: User,
  assignmentTypesById: AssignmentTypesById,
  personnelTypesById: PersonnelTypesById,
  assignmentsById: AssignmentsById,
  personnelById: PersonnelById,
  templatesById: TemplatesById,
  departmentsById: DepartmentsById,
  useMultiSMProviders: boolean
): ColumnDefinition => {
  switch (columnName) {
    case "Assignment":
      return {
        title: "Assignment",
        data: (slotquest) => {
          return getSlotDisplayObject(settings, ui, slotquest).assignmentText;
        },
        sortAttribute: (slotquest) => slotquest.assignCompactOrDisplayName,
        classes: (_) => "",
      };
    case "Assignment Type":
      return {
        title: "Assignment Type",
        data: (slotquest) => {
          return slotquest.assign_atype ? assignmentTypesById[slotquest.assign_atype].name : "";
        },
        sortAttribute: (slotquest) => {
          return slotquest.assign_atype ? assignmentTypesById[slotquest.assign_atype].name : "";
        },
        classes: (_) => "",
      };
    case "Assignment Information":
      return {
        title: "Assignment Information",
        data: (slotquest) => {
          return assignmentsById[slotquest.condensedStructureId]?.assignmentToNoteMap[slotquest.assignId];
        },
        sortAttribute: (slotquest) => {
          return assignmentsById[slotquest.condensedStructureId]?.assignmentToNoteMap[slotquest.assignId];
        },
        classes: (_) => "col-small wordwrap",
      };
    case "Assignment Units":
      return {
        title: "Assignment Units",
        data: (slotquest) => {
          return isSlot(slotquest) ? slotquest.workUnits : 0;
        },
        sortAttribute: (slotquest) => {
          return isSlot(slotquest) ? slotquest.workUnits : 0;
        },
        classes: (_) => "",
      };
    case "Business Phone":
      return {
        title: "Business Phone",
        data: (slotquest) => {
          const { businessPhoneNumber } = personnelById[slotquest.empId];
          return phoneNumberFactory(businessPhoneNumber);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].businessPhoneNumber;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].businessPhoneNumber)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Cell Phone":
      return {
        title: "Cell Phone",
        data: (slotquest) => {
          const { cellPhoneNumber } = personnelById[slotquest.empId];
          return phoneNumberFactory(cellPhoneNumber);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].cellPhoneNumber;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].cellPhoneNumber)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Date":
      return {
        title: "Date",
        data: (slotquest) => {
          return formatFullHumanizedDate(slotquest.date);
        },
        sortAttribute: (slotquest) => {
          if (!slotquest.startTimeString) {
            // need to look it up
            const assignment = assignmentsById[slotquest.assignStructureId];
            const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
            return `${slotquest.dateString}T${times[0]}`;
          }

          return slotquest.startTimeString;
        },
        classes: (_) => "",
      };
    case "Department":
      return {
        title: "Department",
        data: (slotquest) => {
          const template = templatesById[slotquest.templateId];
          return departmentsById[template.departmentId].name;
        },
        sortAttribute: (slotquest) => {
          const template = templatesById[slotquest.templateId];
          return departmentsById[template.departmentId].name;
        },
        classes: (_) => "",
      };
    case "Email":
      return {
        title: "Email",
        data: (slotquest) => {
          return personnelById[slotquest.empId].email;
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].email;
        },
        classes: (_) => "",
      };
    case "Home Phone":
      return {
        title: "Home Phone",
        data: (slotquest) => {
          const { homePhoneNumber } = personnelById[slotquest.empId];
          return phoneNumberFactory(homePhoneNumber);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].homePhoneNumber;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].homePhoneNumber)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Message":
      return {
        title: "Message",
        data: (slotquest) => {
          const { smAccount, smDomain, smProvider } = personnelById[slotquest.empId];
          let smp = smProvider;

          if (!useMultiSMProviders) {
            smp = user.secureMessagingClient;
          }
          const secureMessagingChatLink = constructSecureMessagingChatLink(smp, smDomain, smAccount);
          if (smAccount) {
            return (
              <a
                className="no-details"
                onClick={(e) => e.stopPropagation()}
                href={secureMessagingChatLink}
                target="_blank"
                rel={"noreferrer"}
              >
                {constructProviderName(smp)}
              </a>
            );
          } else {
            return;
          }
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].smAccount;
        },
        classes: (slotquest) => {
          if (personnelById[slotquest.empId].smAccount) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Note":
      return {
        title: "Note",
        data: (slotquest) => (isSlot(slotquest) ? slotquest.note : ""),
        sortAttribute: (slotquest) => (isSlot(slotquest) ? slotquest.note : ""),
        classes: (_) => "col-small wordwrap",
      };
    case "Pager":
      return {
        title: "Pager",
        data: (slotquest) => {
          const { pager } = personnelById[slotquest.empId];
          return phoneNumberFactory(pager);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].pager;
        },
        classes: (slotquest) => {
          const pager = personnelById[slotquest.empId].pager;
          if (isPhoneNumber(pager)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Personnel":
      return {
        title: "Personnel",
        data: (slotquest) => {
          const slotDisplayObj = getSlotDisplayObject(settings, ui, slotquest, slotquest.empId.toString());
          slotDisplayObj.displayText = slotDisplayObj.personnelText;

          const [pendingClass, displayElement] = getDisplayElementAndCls(settings, slotDisplayObj);
          const personnel = personnelById[slotquest.empId];
          const secureMessagingClient = personnel.smProvider;

          if (pendingClass === "" && secureMessagingClient) {
            const { smAccount } = personnel;

            if (personnel && smAccount && settings.plugins["sm_p"]) {
              return (
                <a className="no-details" href={constructVoalteProfileLink(secureMessagingClient, smAccount)}>
                  {slotDisplayObj.personnelText}
                </a>
              );
            }
          }

          return displayElement;
        },
        sortAttribute: (slotquest) => slotquest.compactOrDisplayName,
        classes: (_) => "",
      };
    case "Personnel Type":
      return {
        title: "Personnel Type",
        data: (slotquest) => {
          if (slotquest.emp_ptype) {
            return personnelTypesById[slotquest.emp_ptype].name;
          } else {
            // should be pending fill? look at the pending_info dictionary - hopefully it exists
            if (isSlot(slotquest)) {
              const { pendingInfo } = slotquest;
              if (pendingInfo) {
                const { emp_ptype } = pendingInfo;
                return personnelTypesById[emp_ptype].name;
              }
            }
          }
        },
        sortAttribute: (slotquest) => {
          if (slotquest.emp_ptype) {
            return personnelTypesById[slotquest.emp_ptype].name;
          } else {
            // should be pending fill? look at the pending_info dictionary - hopefully it exists
            if (isSlot(slotquest)) {
              const { pendingInfo } = slotquest;
              if (pendingInfo) {
                const { emp_ptype } = pendingInfo;
                return personnelTypesById[emp_ptype].name;
              }
            }
          }
        },
        classes: (_) => "",
      };
    case "Start Time":
      return {
        title: "Start Time",
        data: (slotquest) => {
          if (!slotquest.startTimeString) {
            // need to look it up
            const assignment = assignmentsById[slotquest.assignStructureId];
            const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
            const time = new Date(`${slotquest.dateString}T${times[0]}`);
            return formatLocalTime(settings, time);
          }

          return formatLocalTime(settings, slotquest.startTime);
        },
        sortAttribute: (slotquest) => {
          if (!slotquest.startTimeString) {
            // need to look it up
            const assignment = assignmentsById[slotquest.assignStructureId];
            const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
            const date = new Date(`${slotquest.dateString}T${times[0]}`);
            return timeInMilliseconds(date);
          }

          return timeInMilliseconds(new Date(slotquest.startTimeString));
        },
        classes: (_) => "",
      };
    case "Stop Time":
      return {
        title: "Stop Time",
        data: (slotquest) => {
          if (!slotquest.stopTimeString) {
            // need to look it up -- requests might be weird?
            const assignment = assignmentsById[slotquest.assignStructureId];
            const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
            const time = new Date(`${slotquest.dateString}T${times[1]}`);
            return isSameDay(slotquest.stopTime, slotquest.date)
              ? formatLocalTime(settings, time)
              : formatLocalTimeWithMonthAndDay(settings, time);
          }

          return isSameDay(slotquest.stopTime, slotquest.date)
            ? formatLocalTime(settings, slotquest.stopTime)
            : formatLocalTimeWithMonthAndDay(settings, slotquest.stopTime);
        },
        sortAttribute: (slotquest) => {
          if (!slotquest.stopTimeString) {
            // need to look it up
            const assignment = assignmentsById[slotquest.assignStructureId];
            const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
            const date = new Date(`${slotquest.dateString}T${times[1]}`);

            return timeInMilliseconds(date);
          }

          return timeInMilliseconds(new Date(slotquest.stopTimeString));
        },
        classes: (slotquest) => {
          if (!slotquest.stopTimeString) {
            // need to look it up
            const assignment = assignmentsById[slotquest.assignStructureId];
            const times = assignment.templateToDefaultTimesMap[slotquest.templateId].split("~");
            return times[1] < times[0] ? "nextDay" : "";
          }

          return isSameDay(slotquest.stopTime, slotquest.date) ? "" : "nextDay";
        },
      };
    case "Template":
      return {
        title: "Template",
        data: (slotquest) => slotquest.templateDesc,
        sortAttribute: (slotquest) => slotquest.templateDesc,
        classes: (_) => "",
      };
    case "Location(s)":
      return {
        title: "Location(s)",
        data: (slotquest) => slotquest.locationNames,
        sortAttribute: (slotquest) => slotquest.locationNames,
        classes: (_) => "",
      };
    case "Weekly Hours":
      return {
        title: "Weekly Hours",
        data: (slotquest) => {
          return personnelById[slotquest.empId].weeklyHours;
        },
        sortAttribute: (slotquest) => {
          const { weeklyHours } = personnelById[slotquest.empId];
          return weeklyHours ? weeklyHours : -1;
        },
        classes: (_) => "",
      };
    case "Seniority Date":
      return {
        title: "Seniority Date",
        data: (slotquest) => {
          const { seniorityDate } = personnelById[slotquest.empId];
          if (seniorityDate) {
            return formatUSDate(seniorityDate);
          }
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].seniorityDate;
        },
        classes: (_) => "",
      };
    case "UniqueID":
      return {
        title: "UniqueID",
        data: (slotquest) => {
          return personnelById[slotquest.empId].uniqueId;
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].uniqueId;
        },
        classes: (_) => "",
      };
    case "Custom1":
      return {
        title: "Custom1",
        data: (slotquest) => {
          const { custom1 } = personnelById[slotquest.empId];
          return phoneNumberFactory(custom1);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].custom1;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].custom1)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Custom2":
      return {
        title: "Custom2",
        data: (slotquest) => {
          const { custom2 } = personnelById[slotquest.empId];
          return phoneNumberFactory(custom2);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].custom2;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].custom2)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Custom3":
      return {
        title: "Custom3",
        data: (slotquest) => {
          const { custom3 } = personnelById[slotquest.empId];
          return phoneNumberFactory(custom3);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].custom3;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].custom3)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Custom4":
      return {
        title: "Custom4",
        data: (slotquest) => {
          const { custom4 } = personnelById[slotquest.empId];
          return phoneNumberFactory(custom4);
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].custom4;
        },
        classes: (slotquest) => {
          if (isPhoneNumber(personnelById[slotquest.empId].custom4)) {
            return "no-details";
          } else {
            return "";
          }
        },
      };
    case "Category":
      return {
        title: "Category",
        data: (slotquest) => {
          const assignment = assignmentsById[slotquest.assignStructureId];
          return assignment?.structureToCategoryMap[slotquest.assignStructureId] ?? "";
        },
        sortAttribute: (slotquest) => {
          const assignment = assignmentsById[slotquest.assignStructureId];
          return assignment?.structureToCategoryMap[slotquest.assignStructureId] ?? "";
        },
        classes: (_) => "",
      };
    case "Title":
      return {
        title: "Title",
        data: (slotquest) => {
          return personnelById[slotquest.empId].title;
        },
        sortAttribute: (slotquest) => {
          return personnelById[slotquest.empId].title;
        },
        classes: (_) => "",
      };
    case "Total Hours":
      return {
        title: "Total Hours",
        data: (slotquest) => {
          return getPrecisionDifferenceInHours(slotquest.stopTime, slotquest.startTime);
        },
        sortAttribute: (slotquest) => {
          return getPrecisionDifferenceInHours(slotquest.stopTime, slotquest.startTime);
        },
        classes: (_) => "",
      };
    case "Call Order":
      return {
        title: "Call Order",
        data: (slotquest) => {
          return slotquest.callOrder;
        },
        sortAttribute: (slotquest) => slotquest.callOrder,
        classes: (_) => "",
      };
    case "Medical Specialty":
      return {
        title: "Medical Specialty",
        data: (slotquest) => {
          const template = templatesById[slotquest.templateId];
          return departmentsById[template.departmentId].medicalSpecialty;
        },
        sortAttribute: (slotquest) => {
          const template = templatesById[slotquest.templateId];
          return departmentsById[template.departmentId].medicalSpecialty;
        },
        classes: (_) => "",
      };
    default:
      break;
  }

  return { title: "", data: () => "", sortAttribute: (_) => "", classes: (_) => "" };
};

export const getTableColumnDefinitions = (
  columnNames: string[],
  settings: SettingsContext,
  ui: UIContext,
  user: User,
  assignmentTypesById: AssignmentTypesById,
  personnelTypesById: PersonnelTypesById,
  assignmentsById: AssignmentsById,
  personnelById: PersonnelById,
  templatesById: TemplatesById,
  departmentsById: DepartmentsById,
  useMultiSMProviders: boolean
): ColumnDefinition[] => {
  return columnNames?.map((columnName) =>
    getColumnDefinition(
      columnName,
      settings,
      ui,
      user,
      assignmentTypesById,
      personnelTypesById,
      assignmentsById,
      personnelById,
      templatesById,
      departmentsById,
      useMultiSMProviders
    )
  );
};

import React from "react";

import { ColumnDefinition } from "@/viewer/ui/modules/grid/list/types";

interface Props {
  columns: ColumnDefinition[];
  title: string;
}

const ListTableDataSpacer = (props: Props): JSX.Element => {
  const { columns, title } = props;
  return (
    <tr className={"Row Spacer"}>
      <td className="Cell" colSpan={columns.length}>
        <div className="text-container">{title}</div>
      </td>
    </tr>
  );
};

export default ListTableDataSpacer;

import React, { useState } from "react";

import "./_styles.scss";

interface Props {
  addSearchTerm: (searchTerm: string) => void;
  removeSearchTerm: (searchTerm: string) => void;
  searchTerms: string[];
}

const Search = (props: Props): JSX.Element => {
  const { addSearchTerm, removeSearchTerm, searchTerms } = props;
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const searchTags: JSX.Element[] = [];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.which === 13 && currentSearchTerm) {
      addSearchTerm(currentSearchTerm);
      setCurrentSearchTerm("");
    }
  };

  const handleClick = (searchTerm: string) => {
    removeSearchTerm(searchTerm);
    setCurrentSearchTerm("");
  };

  searchTerms.forEach((searchTerm, index) => {
    searchTags.push(
      <span className="search-tag" key={index}>
        <i
          className="fa fa-minus-square"
          onClick={() => handleClick(searchTerm)}
          onTouchEnd={() => handleClick(searchTerm)}
        />
        {searchTerm}
      </span>
    );
  });

  return (
    <div className={"Search"}>
      <div className="search-icon">
        <i className="fa fa-search" />
      </div>
      <div className="search-tags">{searchTags}</div>
      <div className="search-field">
        <input
          type="text"
          value={currentSearchTerm}
          onChange={(e) => setCurrentSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default Search;

/**
 * Utilities for phone related tasks.
 */

/**
 * Matches the following formats:
 *  123-456-7890
 *  (123) 456-7890
 *  123 456 7890
 *  123.456.7890
 *  +91 (123) 456-7890
 *  1234567890
 */
export const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}(,{1,}\d{1,7})?$/;

/***
 * Returns true if the passed string matches the `phoneNumberRegex` and false otherwise.
 *
 * @param value
 */
export const isPhoneNumber = (value: string): boolean => {
  if (!value) {
    return false;
  }

  return !!value.match(phoneNumberRegex);
};

import React, { useEffect, useState } from "react";
import { Box, Dialog, IconButton, Typography, Icon } from "@material-ui/core";
import { SlotTime } from "@/viewer/types/domain/slot";
import { produce } from "immer";
import "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/_styles.scss";

import TimeInputField from "@/_lib/ui/modules/dialog/components/customTimePicker/TimeInputField";

import {
  HOURS_STEP,
  MINUTES_STEP,
  SLOT_MAX_HOURS,
  SLOT_MAX_MINUTES,
  SLOT_MIN_HOURS,
  SLOT_MIN_MINUTES,
} from "@/_lib/ui/modules/dialog/constants";

interface CustomTimePickerProps {
  onClose: () => void;
  onConfirm: (slotStartTime: SlotTime, slotStopTime: SlotTime) => void;
  open: boolean;
  slotStartTime: SlotTime;
  slotStopTime: SlotTime;
}

const initialTime: SlotTime = {
  date: new Date(),
  hours: SLOT_MIN_HOURS,
  minutes: SLOT_MIN_MINUTES,
};

const CustomTimePicker = (props: CustomTimePickerProps): JSX.Element => {
  const { slotStartTime, slotStopTime, onClose, onConfirm, open } = props;

  const [startTime, setStartTime] = useState<SlotTime>(initialTime);
  const [stopTime, setStopTime] = useState<SlotTime>(initialTime);

  useEffect(() => {
    setStartTime(slotStartTime);
  }, [slotStartTime]);

  useEffect(() => {
    setStopTime(slotStopTime);
  }, [slotStopTime]);

  const getBoundedSlotHoursIncrease = (hours: number): number => {
    return hours + HOURS_STEP > SLOT_MAX_HOURS ? SLOT_MIN_HOURS : hours + HOURS_STEP;
  };

  const getBoundedSlotMinutesIncrease = (minutes: number): number => {
    return minutes + MINUTES_STEP > SLOT_MAX_MINUTES ? SLOT_MIN_MINUTES : minutes + MINUTES_STEP;
  };

  const getBoundedSlotHoursDecrease = (hours: number): number => {
    return hours - HOURS_STEP < SLOT_MIN_HOURS ? SLOT_MAX_HOURS : hours - HOURS_STEP;
  };

  const getBoundedSlotMinutesDecrease = (minutes: number): number => {
    return minutes - MINUTES_STEP < SLOT_MIN_MINUTES ? SLOT_MAX_MINUTES : minutes - MINUTES_STEP;
  };

  const handleIncreaseSlotStartHours = () => {
    const currentSlotStartTime = produce(startTime, (draft) => draft);
    const hours = getBoundedSlotHoursIncrease(currentSlotStartTime.hours);

    setStartTime(
      produce(currentSlotStartTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleDecreaseSlotStartHours = () => {
    const currentSlotStartTime = produce(startTime, (draft) => draft);
    const hours = getBoundedSlotHoursDecrease(currentSlotStartTime.hours);

    setStartTime(
      produce(currentSlotStartTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleIncreaseSlotStartMinutes = () => {
    const currentSlotStartTime = produce(startTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesIncrease(currentSlotStartTime.minutes);

    setStartTime(
      produce(currentSlotStartTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleDecreaseSlotStartMinutes = () => {
    const currentSlotStartTime = produce(startTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesDecrease(currentSlotStartTime.minutes);

    setStartTime(
      produce(currentSlotStartTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleIncreaseSlotStopHours = () => {
    const currentSlotStopTime = produce(stopTime, (draft) => draft);
    const hours = getBoundedSlotHoursIncrease(currentSlotStopTime.hours);

    setStopTime(
      produce(currentSlotStopTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleDecreaseSlotStopHours = () => {
    const currentSlotStopTime = produce(stopTime, (draft) => draft);
    const hours = getBoundedSlotHoursDecrease(currentSlotStopTime.hours);

    setStopTime(
      produce(currentSlotStopTime, (draft) => {
        draft.hours = hours;
      })
    );
  };

  const handleIncreaseSlotStopMinutes = () => {
    const currentSlotStopTime = produce(stopTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesIncrease(currentSlotStopTime.minutes);

    setStopTime(
      produce(currentSlotStopTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleDecreaseSlotStopMinutes = () => {
    const currentSlotStopTime = produce(stopTime, (draft) => draft);
    const minutes = getBoundedSlotMinutesDecrease(currentSlotStopTime.minutes);

    setStopTime(
      produce(currentSlotStopTime, (draft) => {
        draft.minutes = minutes;
      })
    );
  };

  const handleConfirm = () => {
    onConfirm(startTime, stopTime);
    onClose();
  };

  return (
    <Dialog hideBackdrop onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <Box display={"flex"} sx={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
        <IconButton id="modal-close-icon" onClick={onClose}>
          <Icon className="fa fa-times" />
        </IconButton>
      </Box>
      <Box
        display={"flex"}
        sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        width={"240px"}
        height={"200px"}
      >
        <Box
          display={"flex"}
          sx={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}
          width={"120px"}
        >
          <TimeInputField
            handleDecreaseClick={handleDecreaseSlotStartHours}
            handleIncreaseClick={handleIncreaseSlotStartHours}
            handleOnChange={(value) =>
              setStartTime(
                produce(startTime, (draft) => {
                  draft.hours = parseInt(value);
                })
              )
            }
            max={SLOT_MAX_HOURS}
            min={SLOT_MIN_HOURS}
            value={startTime.hours}
          />

          <Typography>:</Typography>
          <TimeInputField
            handleDecreaseClick={handleDecreaseSlotStartMinutes}
            handleIncreaseClick={handleIncreaseSlotStartMinutes}
            handleOnChange={(value) =>
              setStartTime(
                produce(startTime, (draft) => {
                  draft.minutes = parseInt(value);
                })
              )
            }
            max={SLOT_MAX_MINUTES}
            min={SLOT_MIN_MINUTES}
            step={MINUTES_STEP}
            value={startTime.minutes.toString().padStart(2, "0")}
          />
          <Typography className="dashSymbol">-</Typography>
          <TimeInputField
            handleDecreaseClick={handleDecreaseSlotStopHours}
            handleIncreaseClick={handleIncreaseSlotStopHours}
            handleOnChange={(value) =>
              setStopTime(
                produce(stopTime, (draft) => {
                  draft.hours = parseInt(value);
                })
              )
            }
            max={SLOT_MAX_HOURS}
            min={SLOT_MIN_HOURS}
            value={stopTime.hours}
          />
          <Typography>:</Typography>
          <TimeInputField
            handleDecreaseClick={handleDecreaseSlotStopMinutes}
            handleIncreaseClick={handleIncreaseSlotStopMinutes}
            handleOnChange={(value) =>
              setStopTime(
                produce(stopTime, (draft) => {
                  draft.minutes = parseInt(value);
                })
              )
            }
            max={SLOT_MAX_MINUTES}
            min={SLOT_MIN_MINUTES}
            step={MINUTES_STEP}
            value={stopTime.minutes.toString().padStart(2, "0")}
          />
        </Box>

        <Box>
          <span className="submit">
            <i className="fa fa-check" onClick={handleConfirm} />
          </span>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CustomTimePicker;

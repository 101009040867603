import React from "react";

import { ColumnDefinition } from "@/viewer/ui/modules/grid/list/types";
import { SettingsContext } from "@/viewer/ui/modules/common/types/context";

import ListTableDataSpacer from "@/viewer/ui/modules/grid/list/ListTableDataSpacer";
import { Moment } from "moment-timezone";

interface Props {
  columns: ColumnDefinition[];
  settings: SettingsContext;
  time: Moment;
}

const ListTableNowDisplay = ({ columns, settings, time }: Props): JSX.Element => {
  const timeStr = settings.useMilitaryTime ? time.format("HH:mm z") : time.format("h:mm A z");
  const title = `Now | ${timeStr}`;

  return <ListTableDataSpacer columns={columns} title={title} />;
};

export default ListTableNowDisplay;

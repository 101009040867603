import React from "react";

import { ColumnDefinition, SortMode } from "@/viewer/ui/modules/grid/list/types";
import ListTableHeaderCell from "@/viewer/ui/modules/grid/list/ListTableHeaderCell";

interface Props {
  columns: ColumnDefinition[];
  sortColumn: ColumnDefinition | undefined;
  setSortColumn: (columnDefinition: ColumnDefinition | undefined) => void;
  sortMode: SortMode;
  setSortMode: (sortMode: SortMode) => void;
}

const ListTableHeaderRow = (props: Props): JSX.Element => {
  const { columns } = props;

  const cells = columns.map((column, i: number) => <ListTableHeaderCell key={i} column={column} {...props} />);

  return <tr className={"Row header"}>{cells}</tr>;
};

export default ListTableHeaderRow;

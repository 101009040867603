import { getNow } from "@/_lib/utils/time";
import { PersonnelById } from "@/viewer/types/domain/personnel";
import { isToday } from "@/viewer/ui/modules/common/helpers/dates";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";

import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import ListTableDataRow from "@/viewer/ui/modules/grid/list/ListTableDataRow";
import ListTableDataSpacer from "@/viewer/ui/modules/grid/list/ListTableDataSpacer";

import ListTableHeaderRow from "@/viewer/ui/modules/grid/list/ListTableHeaderRow";
import ListTableNowDisplay from "@/viewer/ui/modules/grid/list/ListTableNowDisplay";
import { ColumnDefinition, ListViewPartitionCategory, SortMode } from "@/viewer/ui/modules/grid/list/types";

import { checkSearchTerms, getModelTimeState, partitionWorkingSlotQuests } from "@/viewer/ui/modules/grid/list/utils";
import Search from "@/viewer/ui/modules/search/Search";
import moment, { Moment } from "moment-timezone";
import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";

import { setSearchTerms } from "./actions";
import { isAfter, isBefore, isEqual, isSameDay } from "date-fns";
import { isTZAwarenessEnabled } from "@/viewer/utils/timezones";

interface Props {
  columns: ColumnDefinition[];
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  slotquests: SlotOrRequest[];
  personnelById: PersonnelById;
  setSortColumn: (sortColumn: ColumnDefinition | undefined) => void;
  sortColumn: ColumnDefinition | undefined;
  setSortMode: (sortMode: SortMode) => void;
  sortMode: SortMode;
  expandSlot: (slotquest: SlotOrRequest) => void;
  searchTerms: string[];
}

const DEFAULT_PAGE_SIZE = 300;

const ListTable = (props: Props): JSX.Element => {
  const {
    columns,
    data,
    expandSlot,
    settings,
    slotquests,
    personnelById,
    sortColumn,
    setSortColumn,
    sortMode,
    setSortMode,
    tools,
    ui,
    searchTerms,
  } = props;

  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState<SlotOrRequest[]>([]);
  const [time, setTime] = useState(getNow(settings));
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { LbsAppData } = window as any;
  const tzAwarenessEnabled = isTZAwarenessEnabled();

  const timezoneBeforeOrAfter = LbsAppData?.Helpers?.Time?.timezoneBeforeOrAfter();

  // This is to update the time display (NOW | 1:23 PM PST) as well as re-render when the time changes to resort
  // the now, upcoming and finished lists
  React.useEffect(() => {
    const interval = setInterval(() => {
      const newTime: Moment = getNow(settings);
      // save on re-render overhead so we can have a 5 second interval without worry, could even do 1 second if we're
      // feeling spicy
      if (time.format("hh:mm") !== newTime.format("hh:mm")) {
        return setTime(newTime);
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const incrementPage = () => {
    const data = filteredData ? filteredData : slotquests;
    if (currentPage < Math.floor(data.length / DEFAULT_PAGE_SIZE)) setCurrentPage(currentPage + 1);
  };

  const decrementPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const filterSlotQuests = (searchTerms: string[], resetPage = false) => {
    let filteredSlotQuests;

    if (searchTerms.length === 0) {
      filteredSlotQuests = slotquests.filter((slotquest) => slotquest.available);
    } else {
      filteredSlotQuests = slotquests.filter((slotquest) => checkSearchTerms(slotquest, searchTerms));
    }

    setFilteredData(filteredSlotQuests);
    setCurrentPage(resetPage ? 0 : currentPage);
  };

  const isKnownSearchTerm = (searchTerm: string) => {
    return searchTerms.includes(searchTerm);
  };

  const addSearchTerm = (searchTerm: string) => {
    if (isKnownSearchTerm(searchTerm)) return;

    dispatch(setSearchTerms([...searchTerms, searchTerm]));
  };

  const removeSearchTerm = (searchTerm: string) => {
    if (isKnownSearchTerm(searchTerm)) {
      const index = searchTerms.indexOf(searchTerm);
      const dereferencedSearchTerms = [...searchTerms];

      dereferencedSearchTerms.splice(index, 1);

      dispatch(setSearchTerms(dereferencedSearchTerms));
    }
  };

  React.useEffect(() => filterSlotQuests(searchTerms), [slotquests, searchTerms]);

  const slotquestsToPartition = filteredData ? filteredData : slotquests;

  const filterTimezonedSlotsQuests = () => {
    // Selected date range
    let schedule_start_date = LbsAppData.DateManager.attributes.schedule_start_date.format("YYYYMMDD");
    const schedule_stop_date = LbsAppData.DateManager.attributes.schedule_stop_date.format("YYYYMMDD");

    // Subtract 1 day from start date to include overnight shifts (subtracted on the query params as well)
    schedule_start_date = moment(schedule_start_date).subtract(1, "days");

    // Set time to midday to avoid changes on date when parsing to js Date
    const start_str = moment(schedule_start_date).set({ hours: 12 }).toString();
    const end_str = moment(schedule_stop_date).set({ hours: 12 }).toString();

    const start_date = new Date(start_str);
    const end_date = new Date(end_str);

    // Set start of day for start date
    start_date.setHours(0, 0, 0);

    // Set end of day for end date
    end_date.setHours(23, 59, 59);

    const dataToDisplay = slotquestsToPartition.filter((item) => {
      const item_date = item.date;
      const item_stopTime = item.stopTime;

      // Set start of day for item date
      item_date.setHours(0, 0, 0);

      const isShiftDateAfterOrSameAsStartDate = isEqual(item_date, start_date) || isAfter(item_date, start_date);
      const isShiftDateBeforeOrSameAsEndDate = isEqual(item_date, end_date) || isBefore(item_date, end_date);
      const isShiftDateInsideTheRange = isShiftDateAfterOrSameAsStartDate && isShiftDateBeforeOrSameAsEndDate;

      const sameDay = isEqual(item_date, start_date);
      const isOvernightShift = !isSameDay(item_stopTime, item_date);
      const isOvernightShiftAndBeforeStartDate = isOvernightShift && sameDay;

      const isUserTimezoneAfterDbTimezone = timezoneBeforeOrAfter === "after";
      const isSameDayShiftAndTZAfter = sameDay && isUserTimezoneAfterDbTimezone;

      // Display slot/request if they are within the selected date range and if they are overnight shifts that fall within the rage
      if (isShiftDateInsideTheRange || isOvernightShiftAndBeforeStartDate) {
        // If user's timezone if after db timezone, the slot is same day shift and we are not on daily mode view, do not display
        if (isSameDayShiftAndTZAfter && !isToday(settings, ui)) {
          return;
        }
        return item;
      }
      return;
    });

    return dataToDisplay;
  };

  const timezonedSlotsQuests = tzAwarenessEnabled ? filterTimezonedSlotsQuests() : slotquestsToPartition;

  const partitionedSlotQuests =
    sortMode === SortMode.Default
      ? partitionWorkingSlotQuests(settings, ui, timezonedSlotsQuests)
      : timezonedSlotsQuests;

  const numberOfSlotQuests = partitionedSlotQuests.length;
  const current: ReactNode[] = [];
  const finished: ReactNode[] = [];
  const upcoming: ReactNode[] = [];

  let startIndex = 0,
    stopIndex = 0;

  if (numberOfSlotQuests > 0) {
    startIndex = currentPage * DEFAULT_PAGE_SIZE;
    stopIndex = Math.min(numberOfSlotQuests, startIndex + DEFAULT_PAGE_SIZE);

    const categoryIndices: { [key: string]: number } = {
      [ListViewPartitionCategory.Upcoming]: 0,
      [ListViewPartitionCategory.Current]: 0,
      [ListViewPartitionCategory.Finished]: 0,
    };

    for (let i = startIndex; i < stopIndex; i++) {
      const category = getModelTimeState(settings, ui, partitionedSlotQuests[i]);

      const row = (
        <ListTableDataRow
          key={i}
          index={categoryIndices[category]}
          data={data}
          settings={settings}
          ui={ui}
          tools={tools}
          columns={columns}
          personnelById={personnelById}
          onRowClick={expandSlot}
          slotquest={partitionedSlotQuests[i]}
          isKnownSearchTerm={isKnownSearchTerm}
          addSearchTerm={addSearchTerm}
          removeSearchTerm={removeSearchTerm}
        />
      );

      if (category === ListViewPartitionCategory.Upcoming) {
        upcoming.push(row);
      } else if (category === ListViewPartitionCategory.Current) {
        current.push(row);
      } else {
        finished.push(row);
      }

      categoryIndices[category]++;
    }
  } else {
    upcoming.push(
      <tr className="empty-row" key="empty">
        <td>No results.</td>
      </tr>
    );
  }

  let curPageText;
  if (numberOfSlotQuests) {
    const pageStart = currentPage * DEFAULT_PAGE_SIZE + 1;
    const pageEnd = Math.min(numberOfSlotQuests, startIndex + DEFAULT_PAGE_SIZE);
    curPageText = `${pageStart} - ${pageEnd}`;
  } else {
    curPageText = 0;
  }

  const tableBodyElements: ReactNode[] = [];
  if (isToday(settings, ui)) {
    // today's view will group; any other day will not
    if (current.length) {
      tableBodyElements.push(<ListTableNowDisplay key="now-title" columns={columns} settings={settings} time={time} />);
      tableBodyElements.push(...current);
    }
    if (upcoming.length) {
      tableBodyElements.push(<ListTableDataSpacer key="upcoming-title" title="Upcoming" columns={columns} />);
      tableBodyElements.push(...upcoming);
    }
    if (finished.length) {
      tableBodyElements.push(<ListTableDataSpacer key="finished-title" title="Finished" columns={columns} />);
      tableBodyElements.push(...finished);
    }
  } else {
    if (upcoming.length) {
      tableBodyElements.push(...upcoming);
    }
  }

  return (
    <div className={"ListTable"}>
      <div className={"TableActions"}>
        <Search addSearchTerm={addSearchTerm} removeSearchTerm={removeSearchTerm} searchTerms={searchTerms} />

        <div className="count">({numberOfSlotQuests} Results)</div>

        <div className="controls">
          <div key="paginator" className="pagination">
            <i className="fa fa-step-backward" onClick={decrementPage} onTouchEnd={decrementPage} />
            <span className="current-page">{curPageText}</span>
            <i className="fa fa-step-forward" onClick={incrementPage} onTouchEnd={incrementPage} />
          </div>
        </div>
      </div>
      <div className={"TableContainer"}>
        <table>
          <thead>
            {numberOfSlotQuests > 0 && (
              <ListTableHeaderRow
                columns={columns}
                setSortColumn={setSortColumn}
                sortColumn={sortColumn}
                setSortMode={setSortMode}
                sortMode={sortMode}
              />
            )}
          </thead>
          <tbody>{tableBodyElements}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListTable;

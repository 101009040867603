import React from "react";
import { Box } from "@material-ui/core";
import "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/_styles.scss";
import { HOURS_STEP, SLOT_MAX_MINUTES, SLOT_MIN_HOURS } from "@/_lib/ui/modules/dialog/constants";

interface Props {
  handleDecreaseClick: () => void;
  handleIncreaseClick: () => void;
  handleOnChange: (value: string) => void;
  max: number;
  min: number;
  value: string | number;
  step?: number;
}

const TimeInputField = ({
  handleDecreaseClick,
  handleIncreaseClick,
  handleOnChange,
  max = SLOT_MAX_MINUTES,
  min = SLOT_MIN_HOURS,
  step = HOURS_STEP,
  value,
}: Props): JSX.Element => {
  const [focus, setFocus] = React.useState<boolean>(false);

  const enforceMinMax = (el: HTMLInputElement) => {
    const parsedValue = parseInt(el.value);
    if (!isNaN(parsedValue)) {
      if (parsedValue < parseInt(el.min)) el.value = el.min;
      if (parsedValue > parseInt(el.max)) el.value = el.max;
    }
  };

  const getArrowClass = (direction: "up" | "down") =>
    `fa fa-chevron-${direction} ${focus && max === SLOT_MAX_MINUTES ? "lightblueArrow" : "blackArrow"}`;

  return (
    <Box display="flex" sx={{ flexDirection: "column", alignItems: "center" }}>
      <div className={getArrowClass("up")} onClick={handleIncreaseClick}></div>
      <div className="timeInputContainer">
        <input
          className="timeInput"
          max={max}
          min={min}
          onBlur={() => setFocus(false)}
          onChange={(e) => handleOnChange(e.target.value)}
          onFocus={() => setFocus(true)}
          onInput={(e) => enforceMinMax(e.target as HTMLInputElement)}
          step={step}
          type="number"
          value={value}
        />
      </div>
      <div className={getArrowClass("down")} onClick={handleDecreaseClick}></div>
    </Box>
  );
};

export default TimeInputField;

import React from "react";
import { useSelector } from "react-redux";

import PrintContainer, { PrintType } from "@/_lib/ui/modules/print/PrintContainer";
import { MultiSortColumn, ScheduleData, SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ViewData } from "@/viewer/types/viewdata";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { AppState } from "@/modules/reducer";
import ListTable from "@/viewer/ui/modules/grid/list/ListTable";
import useAssignmentsById from "@/viewer/ui/modules/common/hooks/useAssignmentsById";
import useAssignmentTypesById from "@/viewer/ui/modules/common/hooks/useAssignmentTypesById";
import usePersonnelTypesById from "@/viewer/ui/modules/common/hooks/usePersonnelTypesById";
import useDepartmentsById from "@/viewer/ui/modules/common/hooks/useDepartmentsById";
import useTemplatesById from "@/viewer/ui/modules/common/hooks/useTemplatesById";
import usePersonnelById from "@/viewer/ui/modules/common/hooks/usePersonnelById";
import useCurrentlyLoggedInUser from "@/viewer/ui/modules/common/hooks/useCurrentlyLoggedInUser";
import { getTableColumnDefinitions } from "@/viewer/ui/modules/grid/list/columnDefinitions";
import { sortSlotQuestsByAdvancedSortingRules } from "@/viewer/ui/modules/grid/list/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LDFlagEnums } from "@/_lib/constants/LDFlagEnums";
import useFilteredRequestData from "@/viewer/ui/modules/common/hooks/useFilteredRequestData";
import useFilteredSlotData from "@/viewer/ui/modules/common/hooks/useFilteredSlotData";
import filterForToday from "@/viewer/ui/modules/grid/list/filterForToday";
import { SortMode } from "@/viewer/ui/modules/grid/list/types";
import "@/viewer/ui/modules/grid/list/_styles.scss";
import "@/viewer/ui/modules/grid/list/_responsive.scss";

interface Props {
  printRef: React.MutableRefObject<null>;
  printType: PrintType;
  scheduleData: ScheduleData;
  settings: SettingsContext;
  ui: UIContext;
  viewData: ViewData;
}

const ListPrintView = (props: Props): JSX.Element => {
  const { settings, ui, viewData, printRef, printType, scheduleData } = props;
  const { data, filters, tools } = viewData;
  const { columnNames, view } = settings;

  const { slots: slotCollection, requests: requestCollection } = scheduleData;

  const searchTerms = useSelector((state: AppState) => state.list.searchTerms);

  const assignmentsById = useAssignmentsById(settings);
  const assignmentTypesById = useAssignmentTypesById();
  const personnelTypesById = usePersonnelTypesById();
  const personnelById = usePersonnelById(settings);
  const departmentsById = useDepartmentsById();
  const templatesById = useTemplatesById();
  const user = useCurrentlyLoggedInUser();
  const LDFlags = useFlags();
  const useMultiSMProviders = LDFlags[LDFlagEnums.Lv8512SmProviderEpic];

  const columns = React.useMemo(
    () =>
      getTableColumnDefinitions(
        columnNames,
        settings,
        ui,
        user,
        assignmentTypesById,
        personnelTypesById,
        assignmentsById,
        personnelById,
        templatesById,
        departmentsById,
        useMultiSMProviders
      ),
    [
      columnNames,
      settings,
      ui,
      user,
      assignmentTypesById,
      personnelTypesById,
      assignmentsById,
      personnelById,
      templatesById,
      departmentsById,
      useMultiSMProviders,
    ]
  );

  const searchCorpusParams = React.useMemo(
    () => ({
      assignmentsById,
      assignmentTypesById,
      columnNames,
      departmentsById,
      personnelById,
      personnelTypesById,
      settings,
      templatesById,
      ui,
    }),
    [
      assignmentsById,
      assignmentTypesById,
      columnNames,
      departmentsById,
      personnelById,
      personnelTypesById,
      settings,
      templatesById,
      ui,
    ]
  );

  // Advanced sorting rules defined on View Manager
  const multiSortColumns = React.useMemo(() => {
    return (view.theme.data.multiSortColumns as MultiSortColumn[]) || ([] as MultiSortColumn[]);
  }, [view.theme.data.multiSortColumns]);

  const filterSlotsForToday = (slotquest: SlotOrRequest) => filterForToday(settings, ui, slotquest);

  const filteredSlotData = useFilteredSlotData(settings, ui, filters, data, slotCollection, searchCorpusParams).filter(
    filterSlotsForToday
  );

  const filteredRequestData = useFilteredRequestData(
    settings,
    ui,
    filters,
    data,
    requestCollection,
    searchCorpusParams
  );

  const slotquests: SlotOrRequest[] = React.useMemo(
    () => [...filteredSlotData, ...filteredRequestData],
    [filteredSlotData, filteredRequestData]
  );

  // The advanced sorting flag is active
  const isAdvancedSortingEnabled = Boolean(LDFlags[LDFlagEnums.Lv10019ListViewSorting]);

  // Advanced sorting can be used if the flag is active, the sorting rules have been set
  // and the user is NOT manually sorting by clicking on the column header
  const canUseAdvancedSorting = React.useMemo(
    () => isAdvancedSortingEnabled && Boolean(multiSortColumns?.length),
    [isAdvancedSortingEnabled, multiSortColumns?.length]
  );

  const sortedSlotQuests = React.useMemo(
    () =>
      canUseAdvancedSorting
        ? sortSlotQuestsByAdvancedSortingRules(slotquests, columns, data, multiSortColumns)
        : slotquests,
    [canUseAdvancedSorting, columns, data, multiSortColumns, slotquests]
  );

  return (
    <PrintContainer ref={printRef} showOutsidePrintPreview={false} printType={printType}>
      <div className="ListContainer">
        <ListTable
          columns={columns}
          data={data}
          expandSlot={() => undefined}
          personnelById={personnelById}
          searchTerms={searchTerms}
          setSortColumn={() => undefined}
          setSortMode={() => undefined}
          settings={settings}
          slotquests={sortedSlotQuests}
          sortColumn={undefined}
          sortMode={SortMode.Default}
          tools={tools}
          ui={ui}
        />
      </div>
    </PrintContainer>
  );
};

export default ListPrintView;

import React from "react";

import classnames from "@/_lib/utils/classnames";

import { ColumnDefinition, SortMode } from "@/viewer/ui/modules/grid/list/types";

interface Props {
  column: ColumnDefinition;
  sortColumn: ColumnDefinition | undefined;
  setSortColumn: (columnDefinition: ColumnDefinition | undefined) => void;
  sortMode: SortMode;
  setSortMode: (sortMode: SortMode) => void;
}

const isSortAnchor = (column: ColumnDefinition, sortCol: ColumnDefinition | undefined) => {
  return sortCol && sortCol.title.toLowerCase() === column.title.toLowerCase();
};

const handleClick = ({ column, setSortColumn, sortColumn, setSortMode, sortMode }: Props): void => {
  if (isSortAnchor(column, sortColumn)) {
    if (sortMode === SortMode.Descending) {
      sortMode = SortMode.Default;
      // By default the sort column is undefined as none are selected.
      sortColumn = undefined;
    } else {
      sortMode = SortMode.Descending;
    }
  } else {
    // new sort anchor, default to ascending
    sortColumn = column;
    sortMode = SortMode.Ascending;
  }

  setSortMode(sortMode);
  setSortColumn(sortColumn);
};

const ListTableHeaderCell = (props: Props): JSX.Element => {
  const { column, sortColumn, sortMode } = props;
  const isAnchor = isSortAnchor(column, sortColumn);
  const classes = classnames("Cell", {
    sortAnchor: isAnchor,
  });

  return (
    <th className={classes} onClick={() => handleClick(props)}>
      <div className="text-container">
        <span className="drag-left" />
        {column.title}
        {isAnchor && <i className={sortMode === SortMode.Descending ? "fa fa-sort-desc" : "fa fa-sort-asc"} />}
        <span className="drag-right" />
      </div>
    </th>
  );
};

export default ListTableHeaderCell;

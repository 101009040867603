import { AppAction } from "@/modules/types";

interface SetSearchTerms {
  type: "csv/list-views/search-terms";
  payload: string[];
}

export const setSearchTerms = (terms: string[]): AppAction => ({
  type: "csv/list-views/search-terms",
  payload: terms,
});

export type Action = SetSearchTerms;

import React, { useContext } from "react";

import { CallbackContext } from "@/viewer/ui/modules/common/helpers/callbacks";
import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { ColumnDefinition } from "@/viewer/ui/modules/grid/list/types";
import { getSlotEffects, getSlotQuestUUID } from "@/viewer/utils/domain/slotquests";
import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { PersonnelById } from "@/viewer/types/domain/personnel";
import { isPhoneNumber } from "@/_lib/utils/phone";

interface Props {
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  columnDef: ColumnDefinition;
  slotquest: SlotOrRequest;
  personnelById: PersonnelById;
  addSearchTerm: (value: string) => void;
  removeSearchTerm: (value: string) => void;
  isKnownSearchTerm: (value: string) => boolean;
  onCellClick: (slotquest: SlotOrRequest, openPager: boolean) => void;
}

const sortAndMapArrayToParagraphs = (dataArray: string[]): JSX.Element[] => {
  const sorted = dataArray.sort((a, b) => {
    if (a > b) return 1;
    else if (a < b) return -1;
    else return 0;
  });
  return sorted.map((item, i) => <p key={i}>{item}</p>);
};

const ListTableDataCell = (props: Props): JSX.Element => {
  const {
    columnDef,
    slotquest,
    personnelById,
    addSearchTerm,
    removeSearchTerm,
    isKnownSearchTerm,
    settings,
    ui,
    data,
    tools,
    onCellClick,
  } = props;

  const { openListViewFilterContextMenu, getTargetKeys } = useContext(CallbackContext);

  const targetKeys = getTargetKeys();
  const slotUUID = getSlotQuestUUID(slotquest);

  const scrubPager = (ui: UIContext, personnelById: PersonnelById, slotquest: SlotOrRequest): JSX.Element | string => {
    const { pager } = personnelById[slotquest.empId];

    if (isPhoneNumber(pager)) {
      return (
        <a
          className="no-details underline"
          onClick={(e) => {
            e.stopPropagation();
            onCellClick(slotquest, true);
          }}
        >
          {pager}
        </a>
      );
    }

    return pager;
  };

  const handleClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    if (columnDef.title === "Personnel") {
      const tar = e.target as HTMLTableCellElement;

      if (tar.tagName === "A") {
        e.stopPropagation();
        return;
      }
    }

    onCellClick(slotquest, false);
  };

  const getEffects = () => {
    const { title } = columnDef;

    if (title !== "Assignment" && title !== "Personnel" && !targetKeys.includes(slotUUID)) return {};

    const { style } = getSlotEffects(slotquest, settings, ui, data, tools, title);

    return style;
  };

  const showContextMenu = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
    const value = columnDef.data(slotquest);

    if (typeof value === "string") {
      const isFiltered = isKnownSearchTerm(value);
      const onClick = isFiltered ? () => removeSearchTerm(value) : () => addSearchTerm(value);

      e.preventDefault();

      openListViewFilterContextMenu(isFiltered, value, onClick, {
        top: e.pageY,
        left: e.pageX,
      });
    }
  };

  const columnData = columnDef.data(slotquest);

  let content = columnData;

  if (columnDef.title === "Pager") {
    content = scrubPager(ui, personnelById, slotquest);
  } else if (columnDef.title === "Location(s)") {
    content = sortAndMapArrayToParagraphs(columnData as string[]);
  }

  return (
    <td
      className={`Cell ${columnDef.classes(slotquest)}`}
      style={getEffects()}
      onContextMenu={showContextMenu}
      onClick={handleClick}
    >
      <div className="text-container">{content as React.ReactNode}</div>
    </td>
  );
};

export default ListTableDataCell;

import moment from "moment-timezone";

import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { SettingsContext, UIContext } from "@/viewer/ui/modules/common/types/context";

import { isToday } from "@/viewer/ui/modules/common/helpers/dates";
import { getNow } from "@/_lib/utils/time";
import { isAfter, isBefore } from "date-fns";
import { getBrowserTimezone } from "@/viewer/utils/timezones";

const filterForToday = (settings: SettingsContext, ui: UIContext, slotquest: SlotOrRequest): boolean => {
  if (ui.dateMode === "daily") {
    let startTime, stopTime;
    const { timeZone, timeZoneEnabled } = settings;

    const browserTz = getBrowserTimezone();

    if (timeZone && timeZoneEnabled) {
      startTime = moment.tz(slotquest.startTime, timeZone);
      stopTime = moment.tz(slotquest.stopTime, timeZone);
    } else {
      startTime = moment.tz(slotquest.startTime, browserTz);
      stopTime = moment.tz(slotquest.stopTime, browserTz);
    }

    if (isToday(settings, ui)) {
      const startOfToday = getNow(settings).startOf("day");
      const endOfToday = getNow(settings).endOf("day");
      return !(startTime.isAfter(endOfToday) || stopTime.isBefore(startOfToday));
    } else {
      // Both start date and end date are the same date on daily view
      const startDate = ui.startDate;
      const endDate = ui.startDate;

      // Set time to midday to avoid changes on date when parsing to js Date to avoid mismatching timezones when using moment
      const start_date_str = moment.tz(startDate, browserTz).set({ hours: 12 }).toString();
      const end_date_str = moment.tz(endDate, browserTz).set({ hours: 12 }).toString();

      const start_date = new Date(start_date_str);
      const end_date = new Date(end_date_str);

      // Set start of day for start date
      start_date.setHours(0, 0, 0);

      // Set end of day for end date
      end_date.setHours(23, 59, 59);

      const isVisible = isBefore(startTime.toDate(), end_date) || isAfter(stopTime.toDate(), start_date);

      return isVisible;
    }
  }

  return true;
};

export default filterForToday;

/* eslint-disable no-console */
import moment, { Moment } from "moment-timezone";

import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import {
  DataContext,
  MultiSortColumn,
  SettingsContext,
  SORTING_ORDER,
  UIContext,
  ViewLayoutListColumns,
} from "@/viewer/ui/modules/common/types/context";
import { ColumnDefinition, ListViewPartitionCategory } from "@/viewer/ui/modules/grid/list/types";

import { isToday } from "@/viewer/ui/modules/common/helpers/dates";
import { getNow } from "@/_lib/utils/time";
import { DateTime } from "luxon";

/**
 * Iterates over the provided search terms and returns true if the slot or request's search corpus contains all of the
 * provided terms.
 *
 * @param slotquest
 * @param searchTerms
 */
export const checkSearchTerms = (slotquest: SlotOrRequest, searchTerms: string[]): boolean => {
  if (!slotquest.available) return false;

  // If we don't have either search corpus, just bail out and don't bother filtering.
  if (!slotquest.keyValueSearchCorpus || !slotquest.searchCorpus) return true;

  const keyValueSearchCorpus = slotquest.keyValueSearchCorpus.toLowerCase();
  const searchCorpus = slotquest.searchCorpus.toLowerCase();

  return searchTerms.every((searchTerm) => {
    searchTerm = searchTerm.toLowerCase();

    if (searchTerm.includes("=")) {
      return keyValueSearchCorpus.includes(searchTerm);
    }

    return searchCorpus.includes(searchTerm);
  });
};

/**
 * Determines what partition of the list view the slot or request will fall into.
 *
 * - If the provided time is within the slot or requests time bounds "Current" will be returned.
 * - If the provided time is before the slot or requests start time then "Upcoming" will be returned.
 * - If the provided time is after the slot or requests end time then "Finished" will be returned.
 *
 * @param settings
 * @param ui
 * @param now
 * @param slotquest
 */
export const getModelTimeState = (
  settings: SettingsContext,
  ui: UIContext,
  slotquest: SlotOrRequest
): ListViewPartitionCategory => {
  if (!isToday(settings, ui)) return ListViewPartitionCategory.Upcoming;

  const DBTimeZone = settings.timeZone;
  const timeZoneAware = settings.timeZoneEnabled;

  const slotStartTimeAsDBTimeZone = timeZoneAware
    ? DateTime.fromISO(slotquest.startTimeString)
    : DateTime.fromISO(slotquest.startTimeString, { zone: DBTimeZone ?? "America/New_York" });
  const slotStopTimeAsDBTimeZone = timeZoneAware
    ? DateTime.fromISO(slotquest.stopTimeString)
    : DateTime.fromISO(slotquest.stopTimeString, { zone: DBTimeZone ?? "America/New_York" });

  const slotStartTimeAsLocalTimeZone = slotStartTimeAsDBTimeZone.toLocal();
  const slotStopTimeAsLocalTimeZone = slotStopTimeAsDBTimeZone.toLocal();

  // negative if current time is after start time
  const slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime = slotStartTimeAsLocalTimeZone.diffNow("minutes");

  // negative if current time is after stop time
  const slotStopTimeAsLocalTimeIsAfterLocalNowTime = slotStopTimeAsLocalTimeZone.diffNow("minutes");

  if (
    slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime.get("minutes") <= 0 &&
    slotStopTimeAsLocalTimeIsAfterLocalNowTime.get("minutes") >= 0
  ) {
    return ListViewPartitionCategory.Current;
  } else if (slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime.minutes > 0) {
    return ListViewPartitionCategory.Upcoming;
  } else if (
    slotStartTimeAsLocalTimeIsOnOrAfterLocalNowTime.minutes < 0 &&
    slotStopTimeAsLocalTimeIsAfterLocalNowTime.minutes <= 0
  ) {
    return ListViewPartitionCategory.Finished;
  }

  return ListViewPartitionCategory.Finished;
};

/**
 * Return whether the provided moment is within the slot or request's startTime and stopTime,
 * taking into account timezones.
 *
 * @param slotquest
 * @param time
 * @param tz
 */
export const isWithinSlotQuestTimeBounds = (slotquest: SlotOrRequest, time: Moment): boolean => {
  return time.isAfter(moment(slotquest.startTimeString)) && time.isBefore(moment(slotquest.stopTimeString));
};

/**
 * Partition the slots and requests into working and not working sections within an array.
 *
 * If the dateMode is anything other than `daily`, returns the original array.
 *
 * @param settings
 * @param ui
 * @param slotquests
 */
export const partitionWorkingSlotQuests = (
  settings: SettingsContext,
  ui: UIContext,
  slotquests: SlotOrRequest[]
): SlotOrRequest[] => {
  if (ui.dateMode === "daily") {
    const now = getNow(settings);
    const working: SlotOrRequest[] = [];
    const notWorking: SlotOrRequest[] = [];

    slotquests.forEach((slotquest) => {
      if (isWithinSlotQuestTimeBounds(slotquest, now)) {
        working.push(slotquest);
      } else {
        notWorking.push(slotquest);
      }
    });

    return [...working, ...notWorking];
  } else {
    return slotquests;
  }
};

const TextTypeColumns = [
  ViewLayoutListColumns.ASSIGNMENT,
  ViewLayoutListColumns.ASSIGNMENT_INFORMATION,
  ViewLayoutListColumns.ASSIGNMENT_TYPE,
  ViewLayoutListColumns.BUSINESS_PHONE,
  ViewLayoutListColumns.CATEGORY,
  ViewLayoutListColumns.CELL_PHONE,
  ViewLayoutListColumns.CUSTOM_1,
  ViewLayoutListColumns.CUSTOM_2,
  ViewLayoutListColumns.CUSTOM_3,
  ViewLayoutListColumns.CUSTOM_4,
  ViewLayoutListColumns.DEPARTMENT,
  ViewLayoutListColumns.EMAIL,
  ViewLayoutListColumns.HOME_PHONE,
  ViewLayoutListColumns.LOCATIONS,
  ViewLayoutListColumns.MESSAGE,
  ViewLayoutListColumns.PAGER,
  ViewLayoutListColumns.PERSONNEL,
  ViewLayoutListColumns.PERSONNEL_TYPE,
  ViewLayoutListColumns.TEMPLATE,
  ViewLayoutListColumns.TITLE,
  ViewLayoutListColumns.NOTE,
  ViewLayoutListColumns.UNIQUE_ID,
];

const NumberTypeColumns = [
  ViewLayoutListColumns.ASSIGNMENT_UNITS,
  ViewLayoutListColumns.CALL_ORDER,
  ViewLayoutListColumns.TOTAL_HOURS,
  ViewLayoutListColumns.WEEKLY_HOURS,
];

/**
 * Sort slots/requests based on the sorting rules defined on View Manager
 *
 *
 * @param slotquests - the list of the slots/requests to be sorted
 * @param columns - column definitions where the data to be displayed is calculated and mapped
 * @param data - mapped personnel by id and assignment by id data
 * @param sortColumns - the list of the advanced sorting rules (columns) defined on View Manager
 */
export const sortSlotQuestsByAdvancedSortingRules = (
  slotquests: SlotOrRequest[],
  columns: ColumnDefinition[],
  data: DataContext,
  sortColumns?: MultiSortColumn[]
): SlotOrRequest[] => {
  if (!sortColumns || sortColumns.length === 0) {
    return slotquests; // No sorting needed
  }

  const { assignmentsById, personnelById } = data;

  return slotquests.sort((a: SlotOrRequest, b: SlotOrRequest) => {
    for (const { sortDirection, columnValue } of sortColumns) {
      let direction = sortDirection;

      // We get the values from the column definition due to the calculations done there for displaying the data on the list
      const columnDef = columns.find((item) => item.title === columnValue);
      if (!columnDef) continue; // Skip this column if no definition exists

      let valA = columnDef.sortAttribute(a) as any;
      let valB = columnDef.sortAttribute(b) as any;

      // Set default value for the columns of type text to correctly compare the string values
      if (TextTypeColumns.includes(columnValue)) {
        if (valA === null || valA === undefined) {
          valA = "";
        }
        if (valB === null || valB === undefined) {
          valB = "";
        }
      }
      // Set default value for the columns of type number to correctly compare the number values
      if (NumberTypeColumns.includes(columnValue)) {
        if (valA === null || valA === undefined) {
          valA = 0;
        }
        if (valB === null || valB === undefined) {
          valB = 0;
        }
      }

      if (columnValue === ViewLayoutListColumns.PERSONNEL && sortDirection === SORTING_ORDER.CUSTOM) {
        // Handle the case when the column is "Personnel" and the sorting is set to "Custom"
        // The value to check is the order value of the personnel
        valA = personnelById[a.empId]?.order ?? 0;
        valB = personnelById[b.empId]?.order ?? 0;
        direction = SORTING_ORDER.ASC;
      }

      // Handle the case when the column is "Assignment" and the sorting is set to "Custom"
      if (columnValue === ViewLayoutListColumns.ASSIGNMENT && sortDirection === SORTING_ORDER.CUSTOM) {
        // The value to check is the order value of the assignment
        // Use slot's condensed structure ID which is what matches the Assignment id as passed from the backend
        valA = assignmentsById[a.condensedStructureId]?.order ?? 0;
        valB = assignmentsById[b.condensedStructureId]?.order ?? 0;
        direction = SORTING_ORDER.ASC;
      }

      // When sorting by Date column we ignore the hours, thus we set the value to 0
      if (columnValue === ViewLayoutListColumns.DATE) {
        valA = !isNaN(Date.parse(valA)) ? new Date(new Date(valA).setHours(0, 0, 0, 0)) : null;
        valB = !isNaN(Date.parse(valB)) ? new Date(new Date(valB).setHours(0, 0, 0, 0)) : null;
      }

      // Handle Date type properties
      const dateA = !isNaN(Date.parse(valA)) ? new Date(valA).getTime() : null;
      const dateB = !isNaN(Date.parse(valB)) ? new Date(valB).getTime() : null;

      if (dateA !== null && dateB !== null) {
        const compareResult = dateA - dateB;
        if (compareResult !== 0) return direction === SORTING_ORDER.ASC ? compareResult : -compareResult;
      }

      // Handle string type properties
      if (typeof valA === "string" && typeof valB === "string") {
        const textA = valA.toLocaleLowerCase();
        const textB = valB.toLocaleLowerCase();
        const compareResult = textA.localeCompare(textB, undefined, { numeric: true });
        if (compareResult !== 0) return direction === SORTING_ORDER.ASC ? compareResult : -compareResult;
      }

      // Handle number type properties
      if (typeof valA === "number" && typeof valB === "number") {
        const compareResult = valA - valB;
        if (compareResult !== 0) return direction === SORTING_ORDER.ASC ? compareResult : -compareResult;
      }

      // If the values are of mixed or unsupported types, fall back to default sorting
      if (valA < valB) return direction === SORTING_ORDER.ASC ? -1 : 1;
      if (valA > valB) return direction === SORTING_ORDER.ASC ? 1 : -1;
    }

    // Fall back to default sorting if all columns are equal
    return 0;
  });
};

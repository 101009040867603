import React from "react";
import moment from "moment";

import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import { DataContext, SettingsContext, ToolContext, UIContext } from "@/viewer/ui/modules/common/types/context";
import { ColumnDefinition } from "@/viewer/ui/modules/grid/list/types";
import { PersonnelById } from "@/viewer/types/domain/personnel";

import classnames from "@/_lib/utils/classnames";
import { isSlot } from "@/viewer/utils/domain/slotquests";
import { isToday } from "@/viewer/ui/modules/common/helpers/dates";
import { getNow } from "@/_lib/utils/time";

import ListTableDataCell from "@/viewer/ui/modules/grid/list/ListTableDataCell";

interface Props {
  index: number;
  settings: SettingsContext;
  ui: UIContext;
  data: DataContext;
  tools: ToolContext;
  slotquest: SlotOrRequest;
  personnelById: PersonnelById;
  columns: ColumnDefinition[];
  onRowClick: (slot: SlotOrRequest) => void;
  addSearchTerm: (value: string) => void;
  removeSearchTerm: (value: string) => void;
  isKnownSearchTerm: (value: string) => boolean;
}

const getActiveStatus = (settings: SettingsContext, ui: UIContext, slot: SlotOrRequest): boolean => {
  if (isToday(settings, ui)) {
    const now = getNow(settings);
    const tz = now.tz();

    if (tz) {
      return now.isAfter(moment.tz(slot.startTimeString, tz)) && now.isBefore(moment.tz(slot.stopTimeString, tz));
    } else {
      return now.isAfter(moment(slot.startTimeString)) && now.isBefore(moment(slot.stopTimeString));
    }
  } else {
    return false;
  }
};

const ListTableDataRow = (props: Props): JSX.Element => {
  const {
    addSearchTerm,
    data,
    removeSearchTerm,
    isKnownSearchTerm,
    columns,
    personnelById,
    index,
    onRowClick,
    settings,
    slotquest,
    tools,
    ui,
  } = props;
  const isRequest = !isSlot(slotquest);

  const classes = classnames("Row", {
    request: isRequest,
    granted: isRequest && slotquest.status == "granted",
    denied: isRequest && slotquest.status == "denied",
    suspended: isRequest && slotquest.status == "suspended",
    pending: isRequest && slotquest.status == "pending",
    deleted: isRequest && slotquest.status == "deleted",
    even: index % 2 == 0,
    "on-now": getActiveStatus(settings, ui, slotquest),
  });

  const cells = columns.map((column, index) => (
    <ListTableDataCell
      key={index}
      settings={settings}
      ui={ui}
      data={data}
      tools={tools}
      columnDef={column}
      slotquest={slotquest}
      personnelById={personnelById}
      isKnownSearchTerm={isKnownSearchTerm}
      addSearchTerm={addSearchTerm}
      removeSearchTerm={removeSearchTerm}
      onCellClick={onRowClick}
    />
  ));

  return <tr className={classes}>{cells}</tr>;
};

export default ListTableDataRow;

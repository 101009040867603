import { SlotOrRequest } from "@/viewer/ui/modules/common/types";
import * as React from "react";

export enum SortMode {
  Ascending,
  Descending,
  Default,
}

export interface ColumnDefinition {
  title: string;
  data: (slotquest: SlotOrRequest) => SlotOrRequest[keyof SlotOrRequest] | React.ReactNode;
  sortAttribute: (slotquest: SlotOrRequest) => SlotOrRequest[keyof SlotOrRequest];
  classes: (slotquest: SlotOrRequest) => string;
}

export enum ListViewPartitionCategory {
  Upcoming,
  Current,
  Finished,
}

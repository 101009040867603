import CustomTimePicker from "@/_lib/ui/modules/dialog/components/customTimePicker/CustomTimePicker";
import { SlotData, SlotTimeType } from "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/types";
import { getSlotDefaultTime } from "@/_lib/utils/slotUtils";
import { TWELVE_HOUR_FORMAT } from "@/_lib/utils/time";
import { Assignment } from "@/viewer/types/domain/assignment";
import { SlotTime } from "@/viewer/types/domain/slot";
import { Box, Dialog, Divider } from "@material-ui/core";
import { produce } from "immer";
import { DateTime } from "luxon";
import React, { ChangeEvent, useCallback, useState } from "react";

import "@/_lib/ui/modules/dialog/types/schedule_edits/swaps/move/_styles.scss";
import _ from "lodash";

interface OptionsStageProps {
  assignment: Assignment;
  isAdmin: boolean;
  setSlotData: (slotData: SlotData) => void;
  slotData: SlotData;
}

const DEFAULT_CALL_ORDER = "None";

const CALL_ORDER_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, DEFAULT_CALL_ORDER];

const OptionsStage = (props: OptionsStageProps): JSX.Element => {
  const { slotData, setSlotData, isAdmin, assignment } = props;

  const [customTimeDialogOpen, setCustomTimeDialogOpen] = useState<boolean>(false);
  const [noteDialogOpen, setNoteDialogOpen] = useState<boolean>(false);

  const selectedSlotDate = slotData.selectedDate.toLocaleString(DateTime.DATE_MED);

  let selectedStartTime = DateTime.fromObject({
    hour: slotData.slotStartTime.hours,
    minute: slotData.slotStartTime.minutes,
  }).toFormat(TWELVE_HOUR_FORMAT);

  let selectedEndTime = DateTime.fromObject({
    hour: slotData.slotStopTime.hours,
    minute: slotData.slotStopTime.minutes,
  }).toFormat(TWELVE_HOUR_FORMAT);

  if (slotData.slotTimeType === SlotTimeType.Default && assignment) {
    const defaultTimes = getSlotDefaultTime(assignment, slotData.templateId as number, slotData.dateString);
    selectedStartTime = defaultTimes.startTime;
    selectedEndTime = defaultTimes.endTime;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetData = useCallback(
    _.debounce((data: SlotData) => {
      setSlotData(data);
    }, 50),
    []
  );

  const handleChangeNote = (value: string) => {
    debouncedSetData(
      produce(slotData, (draft) => {
        draft.slotNote = value;
      })
    );
  };

  const handleChangeDisplayNameOverride = (value: string) => {
    debouncedSetData(
      produce(slotData, (draft) => {
        draft.displayNameOverride = value;
      })
    );
  };

  return (
    <Box
      display={"flex"}
      sx={{ flexDirection: "column", justifyContent: "flex-start", alignContent: "left" }}
      width={"290px"}
      minHeight={"345px"}
      height={"auto"}
    >
      <div style={{ marginBottom: "5px" }}></div>

      <div className="details">
        <div className="sentence-container">
          <div className="sentence">
            <span className="semi-large">{`${selectedSlotDate}, ${selectedStartTime} - ${selectedEndTime}`}</span>
            <br />
            <span className="large underline">{`${slotData.assignCompactOrDisplayName}`}</span>
            <br />
            <span className="large underline">{`${slotData.compactOrDisplayName}`}</span>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "15px" }}></div>
      <Divider />
      <div style={{ marginBottom: "15px" }}></div>

      <Box width={"100%"}>
        <div className="timeable">
          <div className="timeable-label">Time</div>
          <div className="timeable-value">
            <span
              className="value"
              onClick={() => {
                setSlotData(
                  produce(slotData, (draft) => {
                    draft.slotTimeType = SlotTimeType.Default;
                  })
                );
                setCustomTimeDialogOpen(false);
              }}
            >
              <span className="label">Default</span>
              <input
                type="checkbox"
                data-cy="defaultCheckBox"
                checked={slotData.slotTimeType === SlotTimeType.Default}
                readOnly={true}
                tabIndex={-1}
              />
              <span className="checkmark" data-cy="timeableCheckmark" />
            </span>
            <span
              className="value"
              onClick={() => {
                setSlotData(
                  produce(slotData, (draft) => {
                    draft.slotTimeType = SlotTimeType.Custom;
                  })
                );
                setCustomTimeDialogOpen(true);
              }}
            >
              <span className="label">Custom</span>
              <input
                type="checkbox"
                data-cy="customCheckBox"
                checked={slotData.slotTimeType === SlotTimeType.Custom}
                readOnly={true}
                tabIndex={-1}
              />
              <span className="checkmark" data-cy="customCheckmark" />
            </span>
          </div>
        </div>

        {slotData.slotTimeType === SlotTimeType.Custom && customTimeDialogOpen && (
          <CustomTimePicker
            onConfirm={(slotStartTime: SlotTime, slotStopTime: SlotTime) =>
              setSlotData(
                produce(slotData, (draft) => {
                  draft.slotStartTime = slotStartTime;
                  draft.slotStopTime = slotStopTime;
                })
              )
            }
            slotStartTime={slotData.slotStartTime}
            slotStopTime={slotData.slotStopTime}
            onClose={() => setCustomTimeDialogOpen(false)}
            open={customTimeDialogOpen}
          />
        )}
      </Box>

      <Box width={"100%"}>
        <div className="selectable-order">
          <label className="selectable-order-label" htmlFor="select-call-order">
            Call Order
          </label>

          <select
            name="select-call-order"
            className="selectable-order-value"
            defaultValue={slotData.slotCallOrder ?? DEFAULT_CALL_ORDER}
            onChange={(e) =>
              setSlotData(
                produce(slotData, (draft) => {
                  if (e.target.value === DEFAULT_CALL_ORDER) {
                    draft.slotCallOrder = null;
                    return;
                  }

                  draft.slotCallOrder = e.target.value as unknown as number;
                })
              )
            }
          >
            {Array.from(CALL_ORDER_OPTIONS).map((option) => (
              <option value={option} key={`callOrder_${option}`}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </Box>

      <Box width={"100%"}>
        <div className="textable">
          <label className="textable-label" htmlFor="new-request-note">
            Note
          </label>
          <textarea
            id="new-request-note"
            data-cy="newRequestNoteTextBox"
            className="textable-value"
            rows={1}
            value={slotData.slotNote}
            style={{ resize: "none" }}
            maxLength={5000}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChangeNote(e.target.value)}
          />
          <i className="fa fa-fw fa-ellipsis-v" data-cy="editNoteEllipsis" onClick={() => setNoteDialogOpen(true)} />
        </div>

        {noteDialogOpen && (
          <Dialog
            hideBackdrop
            onClose={() => setNoteDialogOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={noteDialogOpen}
          >
            <div className="DialogNote NoteFull">
              <div className="position-reset">
                <div className="close" onClick={() => setNoteDialogOpen(false)}>
                  <i className="fa fa-check"></i>
                </div>
                <div className="content">
                  <div className="header">Note</div>
                </div>
                <div className="editables">
                  <div className="note-text-container">
                    <textarea
                      value={slotData.slotNote}
                      rows={15}
                      cols={44}
                      placeholder="Edit note"
                      style={{ resize: "vertical" }}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChangeNote(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </Box>

      <Box width={"100%"}>
        <div className="customizable">
          <label className="custom-label" htmlFor="custom-display">
            Custom Display
          </label>
          <textarea
            id="custom-display"
            data-cy="textField"
            className="custom-value"
            rows={1}
            value={slotData.displayNameOverride ?? ""}
            style={{ resize: "none" }}
            maxLength={100}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChangeDisplayNameOverride(e.target.value)}
          />
        </div>
      </Box>

      <Box width={"100%"}>
        <div className="selectable">
          <div className="selectable-label">Reason</div>
          <div className="selectable-value" data-cy="shiftReasonSelctor">
            {slotData.reasonName ?? "No reason"}
          </div>
        </div>
      </Box>

      {isAdmin && (
        <Box width={"100%"}>
          <div className="checkable">
            <div className="checkable-value">
              <span
                className="value"
                onClick={() =>
                  setSlotData(
                    produce(slotData, (draft) => {
                      draft.addDemand = !slotData.addDemand;
                    })
                  )
                }
              >
                <span className="label">Add demand if necessary</span>
                <input type="checkbox" checked={slotData.addDemand} readOnly={true} tabIndex={-1} />
                <span className="checkmark" data-cy="addDemandCheckBox" />
              </span>
            </div>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default OptionsStage;
